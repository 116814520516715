@import "bootstrap/bootstrap";

@btn-font-weight: 300;
@font-family-sans-serif: 'Open Sans', sans-serif, Helvetica, Arial;
@accent_color: #e4422e;
@default_border_radius: 4px;

.border-radius(@radius) {
  -webkit-border-radius: @radius;
     -moz-border-radius: @radius;
          border-radius: @radius;
}

body {
    padding: 0px 0px 20px 0px;
    margin: 0;
    background-color: #F7F7F7;
}

body, label, .checkbox label {
    font-weight: 300;
}

::selection {
    color: #fff; 
    background: #e4422e;
}

a {
    color: #222;
}

a:hover {
    color: @accent_color;
}

a, a:visited, a:focus, a:active, input, button {
    outline: 0 none !important;
}

a, a:visited, a:focus, a:active, a:hover, a:link {
    text-decoration: none !important;
}

@keyframes slideOutLeft {
    to      { transform: translateX(-100%); }
}

@keyframes scaleUp {
    from    { opacity: 0.6; -webkit-transform: scale(0.99); }
    to      { opacity: 1; }
}

@keyframes slideInRight {
    from    { transform:translateX(100%); }
    to      { transform: translateX(0); }
}

@keyframes slideInUp {
    from    { transform:translateY(4%); }
    to      { transform: translateY(0); }
}

.ng-enter           { animation: scaleUp 0.2s both ease-in; z-index: 8888; }
.ng-leave           { display: none; }

input.ng-invalid.ng-touched {
    background-color: #FFE5E5;
}

input.ng-valid.ng-touched {
    background-color: #D9FFDB;
}

.fa-fire {
    color: #FFCB00;
}

.fa-magic {
    color:#B20260;
}

.fa-music {
    color: #E25353;
}

.fa-microphone {
    color: #E25353;
}

.fa-clock-o {
    color: #529FD5;
}

.fa-users {
    color: #870ECD;
}

.btn-primary {
    background-color: #097AD8;
    border-color: #008FDA;
    
    &:hover {
        background-color: #056AC0;
        border-color: #0079E2;
    }
}

.control-label-left {
    margin-bottom: 0;
    padding-top: 7px;
}

.panel-default {
    border-color: #f5f5f5;
    
    .list-group-item {
        border: 1px solid #f5f5f5;
    }
}

.well {
    box-shadow: none;
}

.cursor-pointer {
    cursor: pointer;
}

#error {
    margin: 40px 0px;
    font-size: 20px;
    color: #B0BEC5;
    
    span {
        font-size: 72px;
    }
}

.border-top {
    border-top: 1px solid #EAEAEA;
}

.mp-top {
    margin-top: 10px;
    padding-top: 10px;
}

.mp-top-lg {
    margin-top: 15px;
    padding-top: 15px;
}

.navbar-brand {
    small {
        font-size: 11px;
        font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
        opacity: 0.7;
    }
    .s1 {
        color: #E25353;
    }
    .s2 {
        color:#B20260;
    }
    .s3 {
        color: #870ECD;
    }
}

.cover-full {
    background: url('../img/cover.jpg') no-repeat center center;
    background-size: cover;
}

.cover {
    padding-bottom: 80px;
    min-height: 150px;
    background-color: #ddd;
    background-repeat: no-repeat;
    //background-attachment: fixed;
    background-position: top right;
    background-size: cover;
}

.navbar {
    background-color: rgba(255, 255, 255, 0.95);
    
    .nav {
        a {
            color: #1f1b20 !important;
            font-weight: 600;
        }
        
        .fa-search {
            color: @accent_color;
        }
        
        #header-search {
            position: absolute;
            right: 0;
        }
        
        #header-search, #header-search-xs {
            padding: 10px 10px;
            width: 240px;
            background-color: rgba(255, 255, 255, 0.95);
            text-align: right;
            
            .btn {
                margin-top: 5px;
            }
            
            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }
}

.after-nav {
    background: #1f1b20;
    .border-radius(@default_border_radius);
    color: #FFF;
    height: 50px;
    margin-top: 90px;
    
    .alphabet {
        width: 84% !important;
        line-height: 50px;
        
        ul {
            padding-left: 10px;
            list-style: none;
            
            li {
                float: left;
                line-height: 50px;
                
                a {
                    font-weight: 600;
                    padding: 0px 12px;
                    color: #fff;
                }
                
                &:hover {
                    background: #000;
                }
            }
        }
        
        @media (max-width: 1200px) {
            ul li a { padding: 0px 9px; }
        }
        
        @media (max-width: 992px) {
            width: 95% !important;
            
            ul li a { padding: 0px 7px; }
        }
    }
    
    .submit-lyric {
        background: @accent_color;
        width: 16%;
        border-top-right-radius: @default_border_radius;
        border-bottom-right-radius: @default_border_radius;
        -webkit-border-top-right-radius: @default_border_radius;
        -webkit-border-bottom-right-radius: @default_border_radius;
        -moz-border-top-right-radius: @default_border_radius;
        -moz-border-bottom-right-radius: @default_border_radius;
        line-height: 50px;
        .transition(background-color 0.5s);
        
        a {
            color: #fff;
            font-weight: 600;
            width: 100%;
            line-height: 50px !important;
            display: block;
            font-size: 16px;

            @-webkit-keyframes pulse {to {box-shadow:0 0 0 10px rgba(232, 76, 61, 0);}}
            @-moz-keyframes pulse {to {box-shadow:0 0 0 10px rgba(232, 76, 61, 0);}}
            @-ms-keyframes pulse {to {box-shadow:0 0 0 10px rgba(232, 76, 61, 0);}}
            @keyframes pulse {to {box-shadow:0 0 0 10px rgba(232, 76, 61, 0);}}

            &:before {
                content: "";
                position: absolute;
                right: 35px;
                bottom: -3px;
                display: block;
                width: 13px;
                height: 13px;
                box-shadow: 0 0 0 0 rgba(220, 41, 19, 0.7);
                border-radius: 50%;
                background-color: darken(@accent_color, 10%);
                -webkit-animation:pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
                -moz-animation:pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
                -ms-animation:pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
                animation:pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
            }

            .fa {
                margin-right: 4px;
            }
        }
        
        &:hover {
            background-color: darken(@accent_color, 10%);

            a {
                &:before {
                    display: none;
                }
            }
        }
        
        @media (max-width: 992px) {
            width: 5% !important;
	}
    }
}

.hero-index {
    padding: 70px 0px 140px 0px;
    
    h1 {
        color: #fff;
        font-size: 35px;
        font-weight: bold;
    }
    
    .search-form {
        margin-top: 35px;
        height: 60px;
        
        .search-wrapper {
            width: 60%;
            margin: 0 auto;

            .search-input {
                width: 100%;
                input {
                    width: 100%;
                    height: 60px;
                    background: rgba(255,255,255,.9);
                    border-radius: 30px;
                    border: solid 5px rgba(255,255,255,.4);
                    padding-left: 20px;
                }
            }
            .search-button {
                width: 20px;
                margin-left: -40px;

                label {
                    margin-top: 20px;

                    .fa {
                        color: @accent_color;
                        cursor: pointer;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

.main-content {
    background-color: #fff;
    padding: 0px 20px 20px 20px;
    margin-top: -40px;
    .border-radius(@default_border_radius);
    
    h1 {
        font-size: 24px;
    }
    
    .latest {
        h3 {
            .fa {
                color: #FFCB00;
            }
        }
        
        ul {
            list-style: none;
            margin: 20px 0px 0px 0px;
            padding: 0;
            
            li {
                float: left;
                padding: 16px 16px 12px 50px;
                position: relative;
                color: #222;
                
                .index-latest {
                    position: absolute;
                    left: 20px;
                }
                 
                h5 {
                    padding: 0;
                    margin: 0;
                    font-weight: 600;
                }
                
                > a {
                    height: 38px;
                    display: inline-block;
                    overflow: hidden;
                }
                
                small {
                    display: block;
                    text-align: right;
                }
                
                &:nth-child(2n+1) {
                    background: #f5f5f5;
                }
                
                @media (max-width: 768px) {
                    &:nth-child(2n+1) {
                        background: #fff;
                    }
                    
                    &:nth-child(2), &:nth-child(3), &:nth-child(6), &:nth-child(7) {
                        background: #f5f5f5;
                    }
                }
            }
        }
    }
    
    .latest.alternate {
        ul {
            li{
                padding-left: 25px;
                
                > a {
                    height: 18px;
                    display: inline-block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                
                /*background: #fff;
                
                &:nth-child(4n+4), &:nth-child(4n+1) { 
                    background: #f5f5f5; 
                }*/
            }
        }
    }
    
    a.artist { 
        span {
            bottom: -37px;
            display: block;
            background-color: rgba(242, 242, 242, 0.9);
            height: 37px;
            overflow: hidden;
            width: 120px;
            text-align: center;
            color: #000;
            font-weight: 600;
            font-size: 13px;
            position: absolute;
            .transition(bottom 0.2s);
        }

        &:hover {
            span {
                bottom: 0px;
            }
        }
    }
    
    .artists {
        margin: 20px 0px 20px 0px;
        
        div {
            height: 197px;
            overflow: hidden;
            margin-bottom: 20px;
            
            a.artist { 
                span {
                    bottom: 0px;
                }
            }
        }
    }
    
    .top-users {
        ol {
            li {
                a {
                    small {
                        color: #989898;
                    }
                }
            }
        }
    }
}

#social-login {
    position: relative;
    margin: 30px 0 0 0;
    padding: 30px 0 0 0;
    border-top: 1px solid #F7F7F7;
    
    a {  
        height: 30px;
        line-height: 30px;
        display: inline-block;
        border-radius: 4px;
        
        span {
            margin: -1px 5px 7px 5px;
            display: block;
            float: left;
            color: #fff;
            font-weight: 600;
            font-size: 13px;
        }
        
        .fa {
            font-size: 28px;
            color: #fff;
        }
        
        .fa-facebook-official {
            margin: 1px 3px 0px 0px;
        }
        
        .fa-google {
            font-size: 27px;
            margin: 2px 3px 0px 0px;
        }
        
        &:hover {
            color: #fff;
        }
    }
    
    .facebook {
        background-color: #4e69a2;
        
        &:hover {
            background-color: #4e69a2;
            
            .fa {
                color: #fff;
            }
        }
    }
    
    .google {
        background-color: #d34836;
        
        &:hover {
            background-color: #d34836;
            
            .fa {
                color: #fff;
            }
        }
    }
    
    &:before {
        content: 'sau login cu';
        position: absolute;
        top: 0;
        left: 50%;
        margin: -11px 0 0 -7px;
        padding: 0 3px;
        background-color: #fff;
        font-weight: 600;
        font-size: 13px;
        color: #333;
    }
}

.title {
    margin: 20px 0px 0px 0px;
    text-align: center;
    
    a {
        color: #6B6B6B;
        
        &:hover {
            text-decoration: underline !important;
            color: @accent_color;
        }
    }
}

.feat-artists {
    margin-bottom: 30px;
}

.lyric-body {
    color: #000;
    font-weight: 400;
    
    >div {
        >p {
            padding-bottom: 15px;
        }
    } 
    
    @media (max-width: 768px) {
        .side-comment {
            display: none;
        }
    }
}

#lists {
    position: relative;
    
    .form-horizontal {
        margin: 15px 15px;
        text-align: right;
        
        button {
            margin-top: 5px;
        }
    }

    >div {
        position: absolute;
        left: -10px;
        top: -50px;
        z-index: 9999;
        background-color: #fff;
        border: 1px solid #E7E7E7;
        box-shadow: 1px 1px 1px #E7E7E7;
        min-width: 250px;
        
        h5 {
            margin: 0;
            padding: 15px 15px;
        }
        
        ul{
            list-style: none;
            padding: 0;
            
            li {
                padding: 2px 15px 3px 15px;
                
                span {
                    label {
                        padding-left: 5px;
                        margin-bottom: 0;
                        color: #000;
                        width: calc(100% - 15px);
                        cursor: pointer;
                    }
                }

                &:hover {
                    background-color: #E7E7E7;
                }
            }
        }  
        
        #create-new-list {
            padding-left: 15px;
            padding-bottom: 10px;
            cursor: pointer;
            
            &:hover {
                background-color: #E7E7E7;
            }
        }
    }
    
    .login-message {
        top: 0;
        padding: 15px 15px;
        color: @brand-danger;
        font-weight: 600;
        font-size: 12px;
    }
}

#add-to-list, #share {
    cursor: pointer;
    background: transparent;
    border: 0;

    &:hover {
        color: #000;
    }
}

.share-box, .share-box-inline {
    position: absolute;
    left: 115px;
    top: 0px;
    z-index: 9999;
    background-color: #fff;
    border: 1px solid #E7E7E7;
    box-shadow: 1px 1px 1px #E7E7E7;
    min-width: 250px;
    padding: 10px;
    
    h5 {
        margin: 0;
        padding: 0px 0px 15px 0px;
    }
    
    .button-social {
        .transition(150ms);
        font-size: 12px;
        line-height: 20px;
        color: #fff;
        display: inline-block;
        margin: 0 1px 1px;
        padding: 3px 10px;
        white-space: nowrap;
        cursor: pointer;
        min-width: 28px;
        text-align: center;
        width: 70px;

        .fa {
            font-size: 13px;
            vertical-align: middle;
        }

        .social-label {
            margin: 0px 0px 0px 5px;
        }

        .social-count {
            margin: -2px -10px -3px 3px;
            background-color: rgba(0,0,0,.15);
            padding: 2px 8px;
            display: none;
        }

        &:hover {
            color: #fff;
        }
    }

    .facebook-button-social {
        background-color: #325a97;

        &:hover {
            background-color: #233f69;
        }
    }

    .twitter-button-social {
        background-color: #00aced;

        &:hover {
            background-color: #0080b0;
        }
    }

    .gplus-button-social {
        background-color: #dd4b39;

        &:hover {
            background-color: #b93120;
        }
    }
}

.share-box-inline {
    position: inherit;
    top: inherit;
    left: inherit;
    z-index: 9999;
    background-color: #fff;
    border: 0;
    box-shadow: none;
    padding: 0;
    margin: 0;
}

#comments {
    .title {
        text-transform: uppercase;
        font-weight: 600;
        text-align: left;
    }
    
    form {
        margin: 10px 0px 20px 0px;
        text-align: right;
        
        button {
            margin-top: 10px;
        }
    }
    
    .comment {
        margin-bottom: 20px;
        
        .comment-header {
            a {
                font-weight: 600;
                font-size: 13px;
            }
            small {
                font-size: 11px;
            }
        }
        
        .comment-body {
            color: #000;
        }
    }
}

.lyrics {
    list-style: none;
    padding-left: 0px;
    margin-left: 0px;
    
    li {
        margin: 5px 0px 10px 0px;
        border-bottom: 1px solid #F7F7F7;
        padding-bottom: 5px;
    }
}

.search-results {
    h4 {
        margin: 20px 0px 0px 0px;
    }
}

#right {
    margin-top: 25px;
    
    .embed-responsive, .panel {
        margin-bottom: 25px;
    }
    
    .panel {
        .panel-heading {
            font-weight: 600;
        }
    }
    
    .wordcloud {
        margin-bottom: 25px;
    }
}

.footer {
    text-align: center;
    margin: 20px 0px 0px 0px;
}

.hearts-box {
    .hearts {
        width: 65px;
        height: 600px;
        position: fixed;
        bottom: 30px;
        right: 60px;
    }

    .push-me-heart {
        font-size: 65px;
        color: #e4422e;
        cursor: pointer;
        text-shadow: 1px 4px 3px #ccc;
        position: fixed;
        bottom: 30px;
        right: 60px;
    }

    .heart {
        width: 40px;
        height: 40px;
        opacity: 1;
        position: absolute;
        bottom: 0;
        display: none;

        i {
            position: absolute;
            left: 0;
            top: 0;
            opacity: .9;
        }
    }

    .colOne {
        color: #fce473;
    }

    .colTwo {
        color: #f68b39;
    }

    .colThree {
        color: #ed6c63;
    }

    .colFour {
        color: #847bb9;
    }

    .colFive {
        color: #97cd76;
    }

    .colSix {
        color: #35b1d1;
    }

    @keyframes flowOne {
        0% {
            opacity: 0;
            bottom: 0;
            left: 14%
        }
        40% {
            opacity: .8;
        }
        50% {
            opacity: 1;
            left: 0;
        }
        60% {
            opacity: .2;
        }
        80% {
            bottom: 80%
        }
        100% {
            opacity: 0;
            bottom: 100%;
            left: 18%
        }
    }

    @keyframes flowTwo {
        0% {
            opacity: 0;
            bottom: 0;
            left: 0;
        }
        40% {
            opacity: .8;
        }
        50% {
            opacity: 1;
            left: 11%
        }
        60% {
            opacity: .2;
        }
        80% {
            bottom: 60%
        }
        100% {
            opacity: 0;
            bottom: 80%;
            left: 0;
        }
    }

    @keyframes flowThree {
        0% {
            opacity: 0;
            bottom: 0;
            left: 0;
        }
        40% {
            opacity: .8;
        }
        50% {
            opacity: 1;
            left: 30%
        }
        60% {
            opacity: .2;
        }
        80% {
            bottom: 70%
        }
        100% {
            opacity: 0;
            bottom: 90%;
            left: 0;
        }
    }
}

.push-me-heart {
    cursor: url('/img/v1.cur'), default;
}